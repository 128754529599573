import React from 'react';
import DocumentPage, { DocumentPageContext } from '../components/DocumentPage';
import { OrderConfirmationBM } from '@bm-js/h2o-shared';
import OrderConfirmationCard from '../components/OrderConfirmationCard';

const context: DocumentPageContext<OrderConfirmationBM> = {
  documentName: 'Orderkännande',
  documentNameSpecific: 'Orderkännandet',
  documentKey: 'orderConfirmation',
  documentKeyPlural: 'orderConfirmations',
  documentNamePlural: 'Orderkännanden',
  internalPersonKey: 'sellerReferences',
  contactPersonKey: 'customerReferences',
  pdfNeedsFullCustomer: true,
  enablePdfUpload: false,
  displayFileUploads: false,
  documentIdentifier: 'offerNumber',
  cardComponent: OrderConfirmationCard,
  internalPersonKeyRequired: true,
  cardGridTemplate: '1fr 1fr 1fr 1fr 1fr 20px',
  cardHeaders: [
    {
      text: 'Offertnummer',
      mobile: true,
    },
    {
      text: 'Offertdatum',
    },
    {
      text: 'Antal rader',
    },
    {
      text: 'Summa',
    },
    {
      text: 'Status',
      mobile: true,
    },
  ],
};

const OrderConfirmationsPage = () => {
  return <DocumentPage context={context} />;
};

export default OrderConfirmationsPage;
